import React, { useState } from "react";
import { Helmet } from "react-helmet"; // Import Helmet
import { Timestamp, collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db } from "../Source/firebaseConfig";
import { toast } from "react-toastify";
import "./Contactus.css";

export default function Contactus() {
    const [formData, setFormData] = useState({
        Author: "",
        title: "",
        description: "",
        Phonenum: "",
        image: "",
        createdAt: Timestamp.now().toDate(),
    });

    const [progress, setProgress] = useState(0);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handlePublish = () => {
        if (!formData.title || !formData.description) {
            toast("Please fill all the fields", { type: "error" });
            return;
        }

        const storageRef = ref(
            storage,
            `/images/${Date.now()}${formData.image.name}`
        );

        const uploadImage = uploadBytesResumable(storageRef, formData.image);

        uploadImage.on(
            "state_changed",
            (snapshot) => {
                const progressPercent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(progressPercent);
            },
            (err) => {
                console.log(err);
            },
            () => {
                setFormData({
                    title: "",
                    description: "",
                    image: "",
                    Author: "",
                    Phonenum: "",
                });

                getDownloadURL(uploadImage.snapshot.ref).then((url) => {
                    const articleRef = collection(db, "Contactus");
                    addDoc(articleRef, {
                        title: formData.title,
                        description: formData.description,
                        imageUrl: url,
                        createdAt: Timestamp.now().toDate(),
                        Phonenum: formData.Phonenum,
                        Author: formData.Author,
                    })
                        .then(() => {
                            toast("Your Response sent to Support Team 🎉", {
                                type: "success",
                            });
                            setProgress(0);
                        })
                        .catch((err) => {
                            toast("Try Again", { type: "error" });
                        });
                });
            }
        );
    };

    return (
        <>
            <Helmet> <title>Contact Us </title>   <meta name="keywords" content="Get in touch with Hamleen Studios! We’re here to answer your questions and assist you with our skill development programs, design services, and more. Reach out today for expert support and collaboration opportunities. Your feedback is valuable to us!" />
                <meta name="keywords" content="Contact, Hamleen Studios, support, inquiries, assistance" />
            </Helmet>
            <div className="container" style={{ fontFamily: "Poppins" }}>
                <div className="">
                    <div className="contactustilte">
                        <p>Get in touch with us today – we're here to assist you</p>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Name</label>
                        <input
                            type="text"
                            name="Author"
                            placeholder="Full Name"
                            className="form-control"
                            value={formData.Author}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Email</label>
                        <input
                            type="email"
                            name="title"
                            placeholder="www.example@.com"
                            className="form-control"
                            value={formData.title}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Phone Number</label>
                        <input
                            type="text"
                            name="Phonenum"
                            placeholder="+92123456789"
                            className="form-control"
                            value={formData.Phonenum}
                            onChange={handleChange}
                        />
                    </div>
                    {/* Message */}
                    <label htmlFor="">Message</label>
                    <textarea
                        name="description"
                        className="form-control"
                        cols={5}
                        placeholder="Message"
                        rows={5}
                        value={formData.description}
                        onChange={handleChange}
                    />
                    {progress === 0 ? null : (
                        <div className="progress" style={{ height: "30px", marginTop: "10px" }}>
                            <div
                                className="progress-bar progress-bar-striped"
                                style={{ width: `${progress}%`, paddingLeft: "65px" }}
                            >
                                {`Sending, please wait for notification on the top ...`}
                            </div>
                        </div>
                    )}
                    <button
                        className="form-control mt-2"
                        onClick={handlePublish}
                        style={{ background: "#1EE6AA", color: "black", fontWeight: "bold" }}
                    >
                        Send
                    </button>
                </div>
            </div>
            <section id="contact" className="contact">
                <div className="container" data-aos="fade-up" data-aos-delay="100">
                    <div className="row">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="info-item" data-aos="fade" data-aos-delay="200">
                                    <i className="fa fa-send"></i>
                                    <h3>Address</h3>
                                    <p>A108 Adam Street</p>
                                    <p>New York, NY 535022</p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="info-item" data-aos="fade" data-aos-delay="300">
                                    <i className="fa fa-mobile"></i>
                                    <h3>Call Us</h3>
                                    <p>+1 5589 55488 55</p>
                                    <p>+1 6678 254445 41</p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="info-item" data-aos="fade" data-aos-delay="500">
                                    <i className="fa fa-bell"></i>
                                    <h3>Email</h3>
                                    <p>info@hamleen.com</p>
                                    <p>supportteam@hamleen.com</p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="info-item" data-aos="fade" data-aos-delay="500">
                                    <i className="fa fa-map"></i>
                                    <h3>Open Hours</h3>
                                    <p>Monday - Friday</p>
                                    <p>24/7</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

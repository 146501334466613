import React from 'react'

function Privacypolicy() {
    return (
        <div className='container'>
            <div className='content '>

                <h3 style={{ fontWeight: "bold", textAlign: "center", margin: "25px" }}>Privacy Policy </h3>
                <p>Thank you for visiting our website . At Hamleen.com, we value and respect your
                    privacy. This Privacy Policy is designed to help you understand how we collect, use, disclose, and safeguard your personal information
                    when you visit our website or interact with our services. Please take a moment to review the following information.
                    or changes to this privacy policy.</p>
                <p className='secoundparm'>Information We Collect</p>

                <p style={{ fontWeight: "bold" }}>We may collect various types of information, including:</p>
                <p ><span style={{ color: "blue" }}>Personal Information:</span>  When you contact us or engage with our services, we may collect personal information such as your name, email address,
                    phone number, and any other information you provide.<br />

                    <span style={{ color: "blue" }}>Log Data:</span> Like many websites, we collect information that your browser sends whenever you visit our website ("Log Data").
                    This Log Data may include your IP address, browser type, browser version, the pages of
                    our website that you visit, the time and date of your visit, the time spent on those pages, and other statistics.
                </p>
                <h6 style={{ fontWeight: "bold" }}>How We Use Your Information</h6>
                <p className=''>We use the information we collect for various purposes, including but not limited to:

                    Providing Services: We may use your information to provide our creative agency services and to communicate with you about those services.

                    Improving Our Website: We analyze Log Data to improve the design and content of our website, as well as to troubleshoot technical issues.</p>



                <h6 style={{ fontWeight: "bold" }}>Sharing Your Information</h6>
                <p>We do not sell, trade, or rent your personal information to third parties. However, we may share your information in the following circumstances:
                    <br />
                    With your consent.<br />
                    To comply with legal obligations.<br />
                    To protect our rights, privacy, safety, or property.</p>
                <p />

                <h6 style={{ color: "black" }}>Your Choices</h6>
                <p>You have the right to access, correct, and delete your personal information. If you would like to
                    exercise these rights or have any questions about your
                    data, please contact us at <span style={{ color: "#1EE6AA", fontWeight: "bold" }}>info@Hamleen.com</span> or you can contact our team  by clicking contact us</p>

                <h6 style={{ fontWeight: "bold" }}>Changes to This Privacy Policy</h6>
                <p>We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons.
                    We will notify you of any material changes by posting the new Privacy Policy on this page.</p>























                <div />
                <div />
            </div>

        </div >
    )
}

export default Privacypolicy
import React, { useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Brand from './logonabar.png';

// Navigation Link Component
const NavLink = ({ to, onClick, children }) => (
    <Link className='nav-link' to={to} onClick={onClick}>
        <li className='Line'>{children}</li>
    </Link>
);

function Navbarr() {

    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => setExpanded(!expanded);
    const handleClose = () => setExpanded(false);

    return (
        <Navbar expanded={expanded} collapseOnSelect expand="lg" className="bg-body-tertiary">
            <Container fluid>
                <Navbar.Brand as={Link} to="/">
                    <img
                        src={Brand}
                        alt="Logo"
                        width="115"
                        className="d-inline-block align-text-top navlogo" onClick={handleClose}
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleToggle} />
                <Navbar.Collapse id="responsive-navbar-nav" className={`${expanded ? 'show' : ''}`}>
                    <Nav className="me-auto" style={{ fontWeight: 'bold' }}>
                        <NavLink to="/Aboutusdetail" onClick={handleClose}>Choose Us</NavLink>
                        <NavLink to="/Servicesdeatial" onClick={handleClose}>Capabilities</NavLink>
                        <NavLink to="/Portfolio" onClick={handleClose}>Our Work</NavLink>
                        {/* <NavLink to="/OurProducts" onClick={handleClose}>Products</NavLink>n */}
                        <NavLink to="/Gallery" onClick={handleClose}>Gallery</NavLink>
                        <NavLink to="/Carrers" onClick={handleClose}>Careers</NavLink>
                        <NavLink to="/Team" onClick={handleClose}>Team</NavLink>
                        <NavLink to="/Contactus" onClick={handleClose}>Contact Us</NavLink>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navbarr;

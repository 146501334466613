import React from 'react';
import { Link } from 'react-router-dom';
import "./Footer.css";

function Footer() {
    return (
        <>
            <div id='footer'>
                <section class="deneb_cta">
                    <div class="container">
                        <div class="row align-items-center contentctr">
                            <div class="col-lg-7 ">
                                <div class="cta_content">
                                    <h3>Have any projects in mind?</h3>
                                    <p>Got a project idea? Let's bring it to life with our expert guidance and innovative solutions.</p>
                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="button_box">
                                    <Link to="/Servicsfrom" style={{ textDecoration: "none" }}> <a href="#" class="btnnn">Let's talk</a></Link>
                                </div>
                            </div>

                        </div> <div>
                            <p className="contact-info">
                                <span className="contact-label">Address:</span> hunza aliabad
                                <span className="contact-label">Cell:</span> +923414209244
                                <span className="contact-label">Email:</span> www.info@hamleen.com
                            </p>
                            <div className="copyright" style={{ fontSize: "12px" }}>
                                &copy; Copyright <strong><span>Hamleen PVT LTD</span></strong> All Rights Reserved<br />
                                <span className="credits">Designed by <span>Hamleen</span> PVT LTD.<br />
                                    <span>
                                        <i className="fa fa-chevron-right" style={{ marginTop: "30px", padding: "5px 0px" }}></i>
                                        <Link to="/Privacypolicy" style={{ color: "orange" }}>Privacy policy</Link>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Footer;

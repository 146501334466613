import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './SkilldevelopmentButton.css'; // Import CSS file for styles

const FloatingButton = () => {
    const location = useLocation();

    // Determine if the button should be hidden
    const shouldHideButton = location.pathname.startsWith('/article') || location.pathname === '/Cousess';

    if (shouldHideButton) {
        return null; // Do not render the button
    }

    return (
        <div className="button-container">
            <Link to="/Cousess">
                <button className="button">
                    Skill Development
                </button>
            </Link>
        </div>
    );
};

export default FloatingButton;

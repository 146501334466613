import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet
import "./Gallery.css";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../Source/firebaseConfig";
import ProgressBar from "./Progressbar/Progressbar";
import Footer from "../Footer/Footer.js";

function Gallery() {
    const [articles, setArticles] = useState([]);
    const [visibleArticles, setVisibleArticles] = useState(9); // Number of articles to show initially
    const [loading, setLoading] = useState(true); // Loading state for data fetching
    const [loadedImages, setLoadedImages] = useState({}); // Track loaded images

    useEffect(() => {
        const fetchData = async () => {
            const articleRef = collection(db, "Gallerry");
            const q = query(articleRef, orderBy("createdAt", "desc"));

            try {
                onSnapshot(q, (snapshot) => {
                    const articles = snapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    setArticles(articles);
                    setLoading(false); // Set loading to false once data is fetched
                });
            } catch (error) {
                console.error("Error fetching data: ", error);
                setLoading(false); // Set loading to false in case of error
            }
        };

        fetchData();
    }, []);

    const handleImageLoad = (id) => {
        setLoadedImages((prev) => ({ ...prev, [id]: true }));
    };

    const loadMore = () => {
        setVisibleArticles((prevVisibleArticles) => prevVisibleArticles + 9);
    };

    // Collect all the titles for meta description
    const galleryTitles = articles.map((article) => article.title).join(', ');

    return (
        <>
            {/* Helmet for SEO */}
            <Helmet>
                <title>Company Gallery - Our Team, Events, and Vibrant Environment</title>
                <meta name="description" content={`Explore our vibrant company environment, meet our team, and see highlights from recent events: ${galleryTitles}`} />
            </Helmet>

            <section id="" className="">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>Gallery</h2>
                        <p className={`discriptionp ${loading ? 'blur-text' : ''}`}>
                            Glimpse of our vibrant company environment, team, and events
                        </p>
                    </div>

                    {loading ? ( // Show loading indicator if data is still loading
                        <div className="Loaderitemgallery">
                            <ProgressBar />
                        </div>
                    ) : (
                        <>
                            <div className="row portfolio-container" data-aos="fade-up">
                                {articles.slice(0, visibleArticles).map(
                                    ({ Catagary, id, title, imageUrl, userId }) => (
                                        <div key={id} className="col-lg-4 col-md-12 portfolio-item filter-app">
                                            <div className="portfolio-wrap">
                                                <img
                                                    loading="lazy"
                                                    src={imageUrl}
                                                    className={`img-fluid ${loadedImages[id] ? '' : 'blur-image'}`}
                                                    alt={title}
                                                    onLoad={() => handleImageLoad(id)}
                                                />
                                                <div className={`portfolio-info ${loadedImages[id] ? '' : 'blur-text'}`}>
                                                    <p className={loadedImages[id] ? '' : 'blur-text'}>{title}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                            {visibleArticles < articles.length && (
                                <div className="text-center mt-4">
                                    <button onClick={loadMore} className="loadmorebutton">Load More</button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Gallery;

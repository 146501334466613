import Footer from "../Footer/Footer";
import "./Skill.css";
import React from "react";

function Skilldevelopment() {
    return (<>
        <section id="faq" className="skillfaq">
            <div className="container" data-aos="fade-up">
                <div className="sectiontitleskilldevelopment">
                    <h4>Frequently Asked Questions By Students</h4>
                </div>
                <ul className="faq-list accordion" data-aos="fade-up">
                    <li>
                        <a data-bs-toggle="collapse" data-bs-target="#faq11" className="collapsed">
                            What makes your program different from other skill development programs?
                            <i className="fa fa-chevron-down icon-show"></i>
                        </a>
                        <div id="faq11" className="collapse" data-bs-parent=".faq-list">
                            <p>
                                Our program is unique because we not only cultivate real creatives but also help them become successful entrepreneurs.
                            </p>
                            <p>
                                We offer two courses specifically designed to foster entrepreneurial skills, ensuring our graduates are well-prepared to excel in their fields and make a significant impact in the market.
                            </p>
                        </div>
                    </li>
                    <li>
                        <a data-bs-toggle="collapse" data-bs-target="#faq6" className="collapsed">
                            Why is your skill development program so expensive?
                            <i className="fa fa-chevron-down icon-show"></i>
                        </a>
                        <div id="faq6" className="collapse" data-bs-parent=".faq-list">
                            <p>
                                Our program is designed to deliver exceptional value by focusing on quality over quantity.
                            </p>
                            <p>
                                We don't teach a large number of students at once; instead, we aim to cultivate real creatives who are well-prepared to excel in the market.
                            </p>
                            <p>
                                Our courses are led by industry experts who provide personalized attention and mentorship, ensuring each participant gains the skills and knowledge needed to thrive professionally.
                            </p>
                        </div>
                    </li>
                    <li>
                        <a data-bs-toggle="collapse" data-bs-target="#faq9" className="collapsed">
                            Are there any financial aid or scholarship options available?
                            <i className="fa fa-chevron-down icon-show"></i>
                        </a>
                        <div id="faq9" className="collapse" data-bs-parent=".faq-list">
                            <p>
                                We want to ensure our financial aid reaches those who truly need it.
                            </p>
                            <p>
                                Students must demonstrate their genuine passion and commitment to becoming a creative in front of our team.
                            </p>
                            <p>
                                If they prove their dedication, they will receive a fully free skill development program.
                            </p>
                        </div>
                    </li>
                    <li>
                        <a data-bs-toggle="collapse" data-bs-target="#faq1" className="collapsed">
                            Could you tell me about your program for creatives who have skills but lack effective market experience?
                            <i className="fa fa-chevron-down icon-show"></i>
                        </a>
                        <div id="faq1" className="collapse" data-bs-parent=".faq-list">
                            <p>
                                Our program helps creatives across various domains enter the market and work with major global clients.
                            </p>
                            <p>
                                We cover everything from client acquisition and sales to portfolio building, marketing, branding, and project management.
                            </p>
                        </div>
                    </li>
                    <li>
                        <a data-bs-toggle="collapse" data-bs-target="#faq7" className="collapsed">
                            Will I be able to secure an internship or full-time job upon completing the skill development program?
                            <i className="fa fa-chevron-down icon-show"></i>
                        </a>
                        <div id="faq7" className="collapse" data-bs-parent=".faq-list">
                            <p>
                                Yes, your chances of securing an internship or full-time job significantly improve upon completing the skill development program.
                            </p>
                            <p>
                                By acquiring valuable skills and knowledge, networking, and preparing a strong job application, you enhance your opportunities for employment.
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </section> <Footer /></>
    );
}

export default Skilldevelopment;

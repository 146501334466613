import React, { useState, useEffect } from 'react';
import './Portfolio.css';
import { Link } from 'react-router-dom';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../Source/firebaseConfig';
import Footer from '../Footer/Footer';
import { Helmet } from 'react-helmet'; // Import Helmet

function Portfolio() {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visibleArticles, setVisibleArticles] = useState(6); // Start with 2 rows (assuming 3 images per row)

    useEffect(() => {
        const articleRef = collection(db, 'Portfoilio');
        const q = query(articleRef, orderBy('createdAt', 'desc'));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const fetchedArticles = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setArticles(fetchedArticles);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    // Function to load more articles
    const loadMore = () => {
        setVisibleArticles((prevVisibleArticles) => prevVisibleArticles + 6); // Load 2 more rows
    };

    // Create a dynamic description with client names
    const clientNames = articles.map((article) => article.title).join(', ');

    return (
        <>
            {/* Helmet for SEO */}
            <Helmet>
                <title>Our Work </title>
                <meta name="description" content={`Our clients: ${clientNames}. Check out the amazing work we've done for them!`} />
            </Helmet>

            <div id="portfolio-section" className="portfolio-section">
                <div className="portfolio-container" data-aos="fade-up">
                    <div className="portfolio-title">
                        <h2>Our Work</h2>
                        <p className="portfolio-subtitle">
                            Here it is. What we are doing for our clients.
                        </p>
                    </div>

                    <div className="portfolio-card-container">
                        {loading ? (
                            // Skeleton Loading
                            Array.from({ length: visibleArticles }).map((_, index) => (
                                <div key={index} className="portfolio-card skeleton">
                                    <div className="portfolio-card-image skeleton"></div>
                                    <div className="portfolio-card-overlay skeleton"></div>
                                    <div className="portfolio-card-content">
                                        <div className="portfolio-card-title skeleton"></div>
                                        <div className="portfolio-card-tags skeleton"></div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            // Actual Articles
                            articles.slice(0, visibleArticles).map(({ id, title, Tags, imageUrl }) => (
                                <Link to={`/Detail/${id}`} key={id} className="portfolio-card">
                                    <div className="portfolio-card-image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
                                    <div className="portfolio-card-overlay"></div>
                                    <div className="portfolio-card-content">
                                        <h3 className="portfolio-card-title">{title}</h3>
                                        <p className="portfolio-card-tags">{Tags}</p>
                                    </div>
                                </Link>
                            ))
                        )}
                    </div>

                    {/* Load More Button */}
                    {visibleArticles < articles.length && (
                        <div className="text-center mt-4">
                            <button onClick={loadMore} className="loadmorebutton">
                                Load More
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Portfolio;

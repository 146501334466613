import React, { useState } from "react";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth } from "../Source/firebaseConfig";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export default function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  let navigate = useNavigate();
  const handleSignup = async () => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      updateProfile(auth.currentUser, { displayName: name });
      navigate("/login");
    } catch (error) {
      toast(error.code, { type: "error" });
    }
  };
  return (<div style={{ marginBottom: "118px", fontFamily: "Poppins" }}>
    <div className="border p-3 bg-light mx-auto loginauth"
      style={{ maxWidth: 800, marginTop: "81px" }}
    >
      <h1 style={{ color: "black" }}>Register</h1>
      <div className="form-group">
        <label>Name</label>
        <input
          type="text"

          className="form-control"
          placeholder="Enter your name"
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          className="form-control"
          placeholder="Enter your email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </div>
      <div className="form-group">
        <label>Password</label>
        <input
          type="password"
          className="form-control"
          placeholder="Password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </div>
      <br />
      <button className="btn " onClick={handleSignup} style={{ background: "#1EE6AA", fontWeight: "bold" }}>
        Register
      </button>
      <br />
      I have a account < Link to="/Login" > LOGIN</ Link>
    </div ></div>
  );
}

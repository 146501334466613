import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { doc, onSnapshot } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { db } from "../../Source/firebaseConfig";
import "./Detail.css";

function Detail() {
    const { id } = useParams();
    const [article, setArticle] = useState(null);
    const [loadingImages, setLoadingImages] = useState({
        secoundimgUrl: true,
        thirdimgUrl: true,
    });

    useEffect(() => {
        const docRef = doc(db, "Portfoilio", id);
        const unsubscribe = onSnapshot(docRef, (snapshot) => {
            if (snapshot.exists()) {
                setArticle({ ...snapshot.data(), id: snapshot.id });
            } else {
                console.log("No such document!");
            }
        });
        return () => unsubscribe();
    }, [id]);

    const handleImageLoad = (key) => {
        setLoadingImages((prevState) => ({
            ...prevState,
            [key]: false,
        }));
    };

    return (
        <section className="single-post-content">
            <div className="container">
                <div className="row">
                    {article && (
                        <div className="col-md-12 post-content" data-aos="fade-up">
                            <div className="single-post">
                                <h1 className="mb-5 maintitle">{article.title}</h1>
                                <div className="post-meta" style={{ margin: "25px", textTransform: "uppercase", fontWeight: "900", color: "#1EE6AA" }}>
                                    <h5 className="date">{article.Tags}</h5>
                                </div>
                                <p>{article.introduction}</p>

                                <h3>{article.Titleforfisrt}</h3>
                                <p>{article.discriptionforfirsttitle}</p>

                                {/* First Image */}
                                {loadingImages.secoundimgUrl && <div className="detail-image-skeleton"></div>}
                                <img
                                    src={article.secoundimgUrl}
                                    alt="Second Image"
                                    className={`detail-img ${!loadingImages.secoundimgUrl ? 'loaded' : ''}`}
                                    onLoad={() => handleImageLoad('secoundimgUrl')}
                                    onError={() => setLoadingImages((prev) => ({ ...prev, secoundimgUrl: false }))}
                                />

                                <h3>{article.tileforsecound}</h3>
                                <p>{article.Discriptionforsecound}</p>

                                {/* Second Image */}
                                {loadingImages.thirdimgUrl && <div className="detail-image-skeleton"></div>}
                                <img
                                    src={article.thirdimgUrl}
                                    alt="Third Image"
                                    className={`detail-img ${!loadingImages.thirdimgUrl ? 'loaded' : ''}`}
                                    onLoad={() => handleImageLoad('thirdimgUrl')}
                                    onError={() => setLoadingImages((prev) => ({ ...prev, thirdimgUrl: false }))}
                                />

                                <h3>{article.Tileforthird}</h3>
                                <p>{article.discriptionforthird}</p>

                                <p>{article.conslusion}</p>
                            </div>
                            <div className="startaprjoect container">
                                <Link to="/Servicsfrom" style={{ textDecoration: "none" }}>
                                    <a href="#" className="btnservices">Let's Talk</a>
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}

export default Detail;

import React, { useState, useEffect } from 'react';
import './Bartop.css';
import { Link } from 'react-router-dom';
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../Source/firebaseConfig";
import SkeletonLoading from './SkeletonLoading';

function Bartop() {
    const [latestPost, setLatestPost] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchLatestPost = async () => {
            try {
                const eventNotesCollection = collection(db, "Eventnote");
                const eventNotesQuery = query(eventNotesCollection, orderBy("createdAt", "desc"));
                const eventNotesSnapshot = await getDocs(eventNotesQuery);
                const eventNotesList = eventNotesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                // Get the most recent post
                const mostRecentPost = eventNotesList[0] || null;
                setLatestPost(mostRecentPost);
            } catch (error) {
                console.error("Error fetching latest post: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchLatestPost();
    }, []);

    return (
        <div className="event-notes-container">
            <Link to="/Servicesdeatial" className="note-link" style={{ textDecoration: "none" }}>
                <div id="topbar">
                    {loading ? (
                        // Display skeleton loading components while data is loading
                        <SkeletonLoading />
                    ) : (
                        // Display the title of the latest post if it exists
                        latestPost ? (
                            <p className='discountcopens' style={{ fontSize: "12px" }}>{latestPost.title}</p>
                        ) : (
                            <p className='no-data'></p>
                        )
                    )}
                </div>
            </Link>
        </div>
    );
}

export default Bartop;

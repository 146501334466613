import React from 'react';
import './SkeletonLoading.css';

const SkeletonLoading = () => {
    return (
        <div className="skeleton-container">
            <div className="skeleton-title"></div>
            <div className="skeleton-date"></div>
        </div>
    );
};

export default SkeletonLoading;
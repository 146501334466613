import React from 'react'

import imageone from "./krownCanwas.png"
import Clienttwo from "./llllllllll.png"
import Client3 from "./client-3.png"
import Client4 from "./logo.png"
import Client5 from "./ttech.png"
import Clients6 from "./client-6.png"
import "./Clients.css"
function Clients() {
    return (
        <div>
            <section id="clients" className="clients">

                <div className="container-fluid" data-aos="fade-up">


                    <div className="row gy-4">
                        <div className="col-xl-2 col-md-3 col-6 client-logo">
                            <img src={Clients6} class="img-fluid" alt="img" />
                        </div>
                        <div className="col-xl-2 col-md-3 col-6 client-logo">
                            <img src={Client4} class="img-fluid" alt="img" />
                        </div>

                        <div className="col-xl-2 col-md-3 col-6 client-logo">

                            <img src={imageone} class="img-fluid" alt="img" />

                        </div>


                        <div className="col-xl-2 col-md-3 col-6 client-logo">
                            <img src={Client3} class="img-fluid" alt="img" />
                        </div>
                        <div className="col-xl-2 col-md-3 col-6 client-logo">
                            <img src={Client5} class="img-fluid" alt="img" />
                        </div>
                        <div className="col-xl-2 col-md-3 col-6 client-logo">
                            <img src={Clienttwo} class="img-fluid" alt="img" />
                        </div>
                    </div>

                </div>

            </section>
        </div>
    )
}

export default Clients
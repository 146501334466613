import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState } from "react"; import transitions from "bootstrap"
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import Home from "./components/Home/Home";
import Privacypolicy from "./components/Privacypolicy/Privacypolicy";
import Team from "./components/Team/Team.js";
import Aboutusdetail from "./components/Aboutusdetial/Aboutusdetial";
import Servicesdeatial from "./components/Servicesdetail/Servicesdeatial.js";
import Cousess from "./components/Skilldevelopment/Cousess/Cousess.js";
import Contactus from "./components/Contactus/Contactus.js";
import Carrers from "./components/Carrares/Carrers.js";
import Gallery from "./components/Gallery/Gallery";
import Bartop from "./components/Navbar/Bartop.js";
import Servicsfrom from "./components/Servicesdetail/Servicsfrom/Servicsfrom";
import Singlecourse from "./components/Skilldevelopment/Cousess/Signlecouse.js/Singlecourse.js";
import Portfolio from "./components/Portfoilio/Portfolio.js";
import Detail from "./components/Portfoilio/Detail/Detail.js";
import Skillform from "./components/Skilldevelopment/Skillform.js";
import Applyingform from "./components/Carrares/Applyingform/Applyingform.js";
import Navbarr from "./components/Navbar/Navbarr.js";
import FloatingButton from "./components/SkilldevelopmentButton/Skilldevelopmentbutton.js";
import OurProducts from "./components/OurProducts/OurProducts";
import Contentcreator from "./components/Carrares/Contentcreator/Contentcreator.js";
import IntershipProgroms from "./components/Carrares/IntershipProgroms/IntershipProgroms.js";
import Applyingformintership from "./components/Carrares/IntershipProgroms/Applyingformintership/Applyingformintership.js";
import Mainlogscreen from "./components/mainlogoscreen/Mainlogscreen.js";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'
function App() {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Router>
      <div>
        {!isLoaded && <Mainlogscreen onLoaded={() => setIsLoaded(true)} />}
        {isLoaded && (
          <>
            <Bartop />
            <Navbarr />
            <Routes>
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Home />} />
              <Route path="/team" element={<Team />} />
              <Route path="/aboutusdetail" element={<Aboutusdetail />} />
              <Route path="/servicesdeatial" element={<Servicesdeatial />} />
              <Route path="/cousess" element={<Cousess />} />
              <Route path="/contactus" element={<Contactus />} />
              <Route path="/carrers" element={<Carrers />} />
              <Route path="/ourproducts" element={<OurProducts />} />
              <Route path="/privacypolicy" element={<Privacypolicy />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/article/:id" element={<Singlecourse />} />
              <Route path="/servicsfrom" element={<Servicsfrom />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/applyingform" element={<Applyingform />} />
              <Route path="/applyingformintership" element={<Applyingformintership />} />
              <Route path="/detail/:id" element={<Detail />} />
              <Route path="/contentcreator" element={<Contentcreator />} />
              <Route path="/skillform" element={<Skillform />} />
              <Route path="/intershipprogroms" element={<IntershipProgroms />} />
            </Routes>
            <FloatingButton />
          </>
        )}
      </div>
    </Router>
  );
}

export default App;

import React from 'react'
import "./Homeaboutus.css"
import Aboutiamge from "./featureslight1.jpg"
import Aboutbackimage from './featureslight3.jpg'
import Howwework from "../../Howwework/Howwework"
import { Link } from 'react-router-dom'
function Homeaboutus() {
    return (
        <div>
            <section id="features" className="features">
                <div className="container">
                    <div className="row gy-4 align-items-center features-item">
                        <div className="col-lg-5 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                            <h2>HAMLEEN</h2>
                            <p style={{ fontSize: "14px" }}>Welcome to Hamleen, where creativity knows no bounds! As a premier creative agency, we take immense pride in our ability to turn dreams into reality, ideas into innovations, and concepts into captivating experiences. With a passion for artistry and a commitment to excellence, we have consistently demonstrated that we are the best creative agency in the business</p>
                            <Link to="/Contactus" className="btn btn-get-started">Contact Us</Link>
                        </div>
                        <div className="col-lg-7 order-1 order-lg-2 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="100">
                            <div className="image-stack">
                                <img loading='lazy' src={Aboutiamge} alt="" className="stack-front" />
                                <img loading='lazy' src={Aboutbackimage} alt="" className="stack-back" />
                            </div>
                        </div>
                    </div>
                    <Howwework />
                    <div className="row gy-4 align-items-stretch justify-content-between features-item ">
                        <div className="col-lg-6 d-flex align-items-center features-img-bg" data-aos="zoom-out">
                            <img loading='lazy' src={Aboutbackimage} alt="" className="stack-back" />
                        </div>
                        <div className="col-lg-5 d-flex justify-content-center flex-column" data-aos="fade-up">
                            <h2 style={{ textTransform: "uppercase" }}  >Services</h2>
                            <p style={{ fontSize: "14px" }}> Explore our portfolio, read about our services, and get in touch with us to embark on a creative journey that will redefine how you connect with your audience and leave an indelible mark on your industry. We're not just a creative agency; we're the best creative agency, and we're ready to prove it with every project we take on.</p>

                            <Link to="Servicesdeatial" class="btn btn-get-started align-self-start">Services</Link>
                        </div>
                    </div>
                </div>

            </section >
        </div >
    )
}

export default Homeaboutus
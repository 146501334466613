import React from 'react'

function Progressbar() {
    return (
        <div >
            <div id="page">
                <div id="container">
                    <div id="ring"></div>
                    <div id="ring"></div>
                    <div id="ring"></div>
                    <div id="ring"></div>
                    <div id="h3">LOADING</div>
                </div>
            </div>
        </div>
    )
}

export default Progressbar
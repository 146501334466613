import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyA_kJ01FFrcsWzA67LBUSEI9ijLNIMOl5w",
  authDomain: "company-5dc9f.firebaseapp.com",
  projectId: "company-5dc9f",
  storageBucket: "company-5dc9f.appspot.com",
  messagingSenderId: "1092534036043",
  appId: "1:1092534036043:web:03151e365f6c0e2d1b0fce",
  measurementId: "G-5FLN4MFFHM"
};

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../../Source/firebaseConfig';
import { Link } from 'react-router-dom';
import Progress from '../Progressbar/Progressbar';
import { Helmet } from 'react-helmet';

function IntershipPrograms() {
    const [articles, setArticles] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const articlesPerPage = 6;

    useEffect(() => {
        const articleRef = collection(db, 'InterShipPrograms');
        const q = query(articleRef, orderBy('createdAt', 'desc'));
        onSnapshot(q, (snapshot) => {
            const articles = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setArticles(articles);
        });
    }, []);

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    };

    const filteredArticles = articles.filter((article) =>
        article.title.toLowerCase().includes(searchInput.toLowerCase()) ||
        article.description.toLowerCase().includes(searchInput.toLowerCase())
    );

    const indexOfLastArticle = currentPage * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Set title and meta description
    const pageTitle = "Internship Programs";
    const pageDescription = articles.length > 0 ? filteredArticles[0].title : "Discover various internship opportunities available.";

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
            </Helmet>
            <div id="services" className="services">
                <div className="div-title">
                    <h2>Internships</h2>
                </div>

                <div className="container" data-aos="fade-up">
                    <div className='portfoioldev'>
                        <Link to="/IntershipPrograms" className='Carreslink'>Internship</Link>
                        <Link to="/Carrers" className='intershiplink'>Full-time</Link>
                        <Link to="/Contentcreator" className='creator'>Content Creator</Link>
                    </div>

                    {/* Search Bar */}
                    <div className="search-bar">
                        <input
                            type="text"
                            placeholder="Search internships you are looking for..."
                            value={searchInput}
                            onChange={handleSearchChange}
                        />
                    </div>

                    <div className="row">
                        {articles.length === 0 ? (
                            <div className="Loaderitemjobs">
                                <Progress />
                            </div>
                        ) : filteredArticles.length === 0 ? (
                            <h6>No Internship available</h6>
                        ) : (
                            currentArticles.map(({ id, title, description, numberofseats }) => (
                                <div
                                    key={id}
                                    className="col-md-6 d-flex align-items-stretch"
                                    data-aos="fade-up"
                                    data-aos-delay="100"
                                >
                                    <div className="icon-box">
                                        <h4>{title}</h4>
                                        <p className='description'>{description}</p>
                                        <div className='bottomsectioncontainer'>
                                            <p style={{ fontWeight: "700" }}>
                                                Number of seats: <span style={{ color: "#1ee6aa" }}>{numberofseats}</span>
                                            </p>
                                            <Link to="/Applyingformintership">
                                                <button>Apply</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                    {/* Pagination */}
                    <ul className="pagination" style={{ marginLeft: '18px' }}>
                        {Array.from({ length: Math.ceil(filteredArticles.length / articlesPerPage) }, (_, i) => (
                            <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                <a href="#!" className="page-link" onClick={() => paginate(i + 1)}>
                                    {i + 1}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default IntershipPrograms;

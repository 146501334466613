import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { auth } from "../Source/firebaseConfig";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
export default function Login() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/Contactus");
    } catch (error) {
      toast(error.code, { type: "error" });
    }
  };
  return (
    <>
      <div >
        <div className="border p-3 bg-light mx-auto loginauth "
          style={{ maxWidth: 800, marginTop: "143px", fontFamily: "Poppins" }}
        >
          <h1 style={{ color: "black" }}>Login</h1>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter your email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <br />
          <button className="btn " onClick={handleLogin} style={{ background: "#1EE6AA", }}>
            Login
          </button>
          <br />
          <>
            Don't have an account <Link to="/register" >Signup</Link>
          </>
        </div>
      </div></>
  );
}

import React from 'react'
import { Link } from 'react-router-dom'
import "./Hero.css"

function Hero() {
    return (
        <>
            <section id="hero" className="d-flex justify-cntent-center align-items-center">
                <div id="heroCarousel" data-bs-interval="5000" className="container carousel carousel-fade" data-bs-ride="carousel">

                    <div className="carousel-item active">
                        <div className="carousel-container">

                            <h2 className="animate__animated animate__zoom-in"> <span style={{ color: "#1EE6AA" }}>Hamleen </span> is a full service <br />branding and marketing agency</h2>
                            <p className="animate__animated animate__fadeInUp">Hamleen is a collaborative team of strategists, creatives and marketers at your disposal to create, disperse, manage and maintain all your branding efforts.</p>
                            <a href='/contactus' class="btn-get-started animate__animated animate__fadeInUp scrollto"><Link to="/Contactus" style={{ textDecoration: "none", color: 'black' }}>Contact Us </Link></a>
                        </div>
                    </div>
                    <div className="carousel-item ">
                        <div className="carousel-container">
                            <h2 className="animate__animated animate__fadeInDown">
                                <span style={{ color: "#1EE6AA" }}>Hamleen </span>   we are    Brands Makers </h2>
                            <p className="animate__animated animate__fadeInUp">
                                Hamleen is a collaborative team of strategists, creatives, and marketers at your disposal to create, disseminate, manage, and maintain all your branding efforts.

                            </p>
                            <a href='/contactus' class="btn-get-started animate__animated animate__fadeInUp scrollto"><Link to="/Contactus" style={{ textDecoration: "none", color: 'black' }}>Contact Us </Link></a>
                        </div>
                    </div>
                    <div className="carousel-item ">
                        <div className="carousel-container">
                            <h2 className="animate__animated animate__fadeInDown">
                                <span style={{ color: "#1EE6AA" }}>Hamleen </span> WHERE DO WE FIT IN THIS WORLD? </h2>
                            <p className="animate__animated animate__fadeInUp">
                                Hamleen is here to help Agencies & Brands, Media owners and Publishers, and E-commerce platforms grow their business.
                            </p>
                            <a href='/contactus' class="btn-get-started animate__animated animate__fadeInUp scrollto"><Link to="/Contactus" style={{ textDecoration: "none", color: 'black' }}>Contact Us </Link></a>
                        </div>
                    </div>
                    <a className="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon fa fa-chevron-left" aria-hidden="true"></span>
                    </a>
                    <a className="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
                        <span className="carousel-control-next-icon fa fa-chevron-right" aria-hidden="true"></span>
                    </a>
                </div>
            </section>
            <section id="home-boxes" className="home-boxes">
                <div className="container">
                    <div className="row">
                        <div class="col-md-6 col-lg-3 d-flex align-items-stretch  mb-lg-0" data-aos="fade-up"
                            data-aos-delay="200">
                            <div class="home-box">
                                <div class="icon"><i class="fa fa-dot-circle-o" aria-hidden="true"></i></div>
                                {/* <p className='weare'>we do</p> */}
                                <h4 class="title">Strategy</h4>
                                <p class="detaildicritpion">Whether a company is growing or just starting, we partner with businesses and organisations of all sizes to refine their purpose, discover their voice, understand their customers, and set clear goals.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-lg-0" data-aos="fade-up"
                            data-aos-delay="100" >
                            <div className="home-box">
                                <div className="icon"><i class="fa fa-magic" aria-hidden="true"></i></div>
                                {/* <p className='weare'>we do</p> */}
                                <h4 className="title">Creativity</h4>
                                <p className="detaildicritpion">Creativity fuels our success. We blend technology with talent to craft resonant content. Our innovation makes your brand stand out, capturing attention and driving engagement.


                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch  mb-lg-0" data-aos="fade-up">
                            <div className="home-box">
                                <div className="icon"><i class="fa fa-medium" aria-hidden="true"></i></div>
                                {/* <p className='weare'>we do</p> */}
                                <h4 className="title"> Media  </h4>
                                <p className="detaildicritpion">Successful media planning, buying, and analytics require a deep understanding of consumer behavior. We connect with your audience where they are, capturing their attention in an increasingly distracted world.</p>
                            </div>
                        </div>


                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="fade-up"
                            data-aos-delay="200">
                            <div className="home-box">
                                <div className="icon"><i class="fa fa-microchip" aria-hidden="true"></i></div>
                                {/* <p className='weare'>we do</p> */}
                                <h4 className="title">Tech </h4>
                                <p className="detaildicritpion">We harness advanced technology to propel innovation and streamline processes. Our skilled team crafts solutions that ensure you stay competitive in a fast-paced digital world.

                                    .</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Hero
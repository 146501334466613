import React, { useState } from "react";
import { Timestamp, collection, addDoc } from "firebase/firestore";
import { db } from "../../Source/firebaseConfig"; // Adjust the path based on your file structure
import { toast } from "react-toastify";
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import { Helmet } from "react-helmet"; // Import Helmet
import 'bootstrap/dist/css/bootstrap.min.css';
import './contentcreator.css'; // You can add your custom CSS here
import Footer from "../../Footer/Footer";

const Contentcreator = () => {
    const [formData, setFormData] = useState({
        Author: "",
        title: "",
        description: "",
        Phonenum: "",
        createdAt: Timestamp.now().toDate(),
        hear: "",
        Gender: "",
        socialLinks: ""
    });

    const [progress, setProgress] = useState(0);

    const handleChange = (e) => {
        const updatedFormData = { ...formData, [e.target.name]: e.target.value };
        setFormData(updatedFormData);

        const filledFields = Object.values(updatedFormData).filter(Boolean).length;
        const totalFields = Object.keys(updatedFormData).length;
        setProgress(Math.round((filledFields / totalFields) * 100));
    };

    const handlePublish = async () => {
        const {
            title, description, Author, hear, Gender, Phonenum, socialLinks
        } = formData;

        if (!title || !description || !Author || !hear || !Gender || !Phonenum || !socialLinks) {
            toast.error("Please fill all the fields");
            return;
        }

        try {
            const articleRef = collection(db, "ContentCreator");
            await addDoc(articleRef, {
                ...formData,
                createdAt: Timestamp.now().toDate(),
            });
            toast.success("Thanks for Applying! We’ll review your application and contact you soon.🎉");
            setFormData({
                Author: "",
                title: "",
                description: "",
                Phonenum: "",
                createdAt: Timestamp.now().toDate(),
                hear: "",
                Gender: "",
                socialLinks: ""
            });
            setProgress(0);
        } catch (err) {
            toast.error("Try Again");
        }
    };

    return (
        <>
            <Helmet>
                <title>Content Creators - Hamleen </title>
                <meta
                    name="description"
                    content="Apply to become a content creator for Hamleen. Join us in creating amazing content for social media platforms like TikTok, Instagram, and YouTube!"
                />
            </Helmet>
            <Container className="content-creator-section mt-5">
                <Row>
                    <Col lg={8} className="continerofcreater">
                        <h2>Social Media Content Creator</h2>
                        <p className="typess">TikTok | Instagram | YouTube | Other</p>
                        <p>
                            Are you a social media enthusiast? Do you always keep up with the latest trends? Are you the go-to person among your friends for creating amazing Instagram Stories, TikToks, and Reels? If this describes you, we’d love to have you in our network of social media content creators! As a Creator for Hamleen, you'll produce real-time social media content for a range of brands, tailored to our clients' needs.
                        </p>
                        <h4>You should:</h4>
                        <ul className="createroul">
                            <li>Have experience in content creation (Making TikToks, Instagram Stories, and Reels, etc.)</li>
                            <li>Stay up-to-date on the latest and greatest social media trends and activate on them when requested</li>
                            <li>Have a solid understanding of social content creation</li>
                            <li>(you could be an influencer if you really wanted to, or maybe you even are one already!)</li>
                        </ul>
                        <h4>How it works:</h4>
                        <ul className="createroul">
                            <li>Submit your information and credentials to join our network of creators.</li>
                            <li>
                                When a project comes in, we’ll email our network with project details, rate, and requirements. (i.e., X client needs this in X days).
                            </li>
                            <li>
                                You’ll respond confirming interest. If we’d like to proceed with booking, we will confirm. You will deliver the final product upon the requested deadline.
                            </li>
                        </ul>
                    </Col>
                    <Col lg={4} className="formmm">
                        <h4 className="applytitle">Apply for this position</h4>
                        <Form>
                            <Form.Group controlId="formFirstName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="Author"
                                    placeholder="Full Name"
                                    value={formData.Author}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="formGender">
                                <Form.Label>Gender</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="Gender"
                                    placeholder="Male, Female"
                                    value={formData.Gender}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="formPhone">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="Phonenum"
                                    placeholder="+92123456789"
                                    value={formData.Phonenum}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="formEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="title"
                                    placeholder="example@example.com"
                                    value={formData.title}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="formHear">
                                <Form.Label>How Did You Hear About This Job?</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="hear"
                                    placeholder="Social Media"
                                    value={formData.hear}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="formMessage">
                                <Form.Label>Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="description"
                                    rows={2}
                                    placeholder="Message"
                                    value={formData.description}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="formSocialLinks">
                                <Form.Label>Social Media Links</Form.Label>
                                <Form.Control
                                    rows={2}
                                    as="textarea"
                                    name="socialLinks"
                                    placeholder="Links to your social media profiles"
                                    value={formData.socialLinks}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            {progress > 0 && (
                                <ProgressBar now={progress} label={`Progress: ${progress}%`} className="my-3" />
                            )}
                            <Button
                                className="w-100 mt-3 buttoncolor"
                                onClick={handlePublish}
                            >
                                Apply
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
};

export default Contentcreator;

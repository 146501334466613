import React from 'react'
import './Home.css'
import Hero from './Hero/Hero'
import Clients from './Clients/Clients'
import Homeaboutus from './HomeAboutus/Homeaboutus'
import Footer from '../Footer/Footer'
import Portfoiliorecent from '../Portfoiliorecent/Portfoiliorecent'
import { Helmet } from "react-helmet";

function Home() {
    return (<> <Helmet>
        <title>Hamleen- Do Strategy,Creativity,Media and Tech for business </title>
        <meta name="description" content="Hamleen is a collaborative team of strategists, creatives and marketers at your disposal to create, disperse, manage and maintain all your branding efforts." />
    </Helmet><div>
            <Hero />
            <Clients />
            <Homeaboutus /><Portfoiliorecent />
            <Footer />
        </div ></>

    )
}
export default Home
import React, { useState, useEffect } from 'react';
import './Portfolio.css';
import { Link } from 'react-router-dom';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../Source/firebaseConfig';
import ProgressBar from '../Gallery/Progressbar/Progressbar';
import Footer from '../Footer/Footer';

function Portfoiliorecent() {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true); // State to manage loading status
    const [visibleArticles, setVisibleArticles] = useState(9); // Number of articles to show initially

    useEffect(() => {
        const articleRef = collection(db, 'Portfoilio');
        const q = query(articleRef, orderBy('createdAt', 'desc'));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const fetchedArticles = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setArticles(fetchedArticles);
            setLoading(false); // Set loading to false once data is fetched
        });

        return () => unsubscribe();
    }, []);

    // Function to load more articles
    const loadMore = () => {
        setVisibleArticles((prevVisibleArticles) => prevVisibleArticles + 6);
    };

    return (
        <>
            <div id="portfolio-section" className="portfolio-section">
                <div className="portfolio-container" data-aos="fade-up">
                    <div className="portfolio-title">
                        <h2 style={{ marginTop: "130px" }}>Our Works</h2>
                        <p className="portfolio-subtitle">
                            Here it is. What we are doing for our clients.
                        </p>
                    </div>

                    <div className="portfolio-card-container">
                        {loading ? (
                            // Skeleton Loading
                            Array.from({ length: visibleArticles }).map((_, index) => (
                                <div key={index} className="portfolio-card skeleton">
                                    <div className="portfolio-card-image skeleton"></div>
                                    <div className="portfolio-card-overlay skeleton"></div>
                                    <div className="portfolio-card-content">
                                        <div className="portfolio-card-title skeleton"></div>
                                        <div className="portfolio-card-tags skeleton"></div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            // Actual Articles
                            articles.slice(0, 3).map(({ id, title, Tags, imageUrl }) => (
                                <Link to={`/Detail/${id}`} key={id} className="portfolio-card">
                                    <div className="portfolio-card-image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
                                    <div className="portfolio-card-overlay"></div>
                                    <div className="portfolio-card-content">
                                        <h3 className="portfolio-card-title">{title}</h3>
                                        <p className="portfolio-card-tags">{Tags}</p>
                                    </div>
                                </Link>
                            ))
                        )}
                    </div>

                    <Link to="/Portfolio"> <div className="text-center mt-4">
                        <button onClick={loadMore} className="loadmorebutton">
                            See All Projects
                        </button>
                    </div></Link>


                </div>
            </div>

        </>
    );
}

export default Portfoiliorecent;

import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet
import Footer from '../Footer/Footer';
import "./Team.css";
import Ceo from "./team/team-1.jpg";
import Pm from "./team/team-2.jpg";
import cto from "./team/team-3.jpg";
import account from "./team/team-4.jpg";
import markating from "./team/team-5.jpg";
import Op from "./team/team-6.jpg";

function Team() {
    return (
        <>
            <Helmet>
                <title>Meet Our Team | Hamleen </title>
                <meta name="description" content="Discover the talented individuals behind Hamleen success. Meet our dedicated team members." />
                <meta name="keywords" content="team, Hamleen creative agency , professionals, dedicated, success" />
            </Helmet>
            <div id="team" className="team">
                <div className="container section-title" data-aos="fade-up">
                    <h2>Our Team</h2>
                    <p>Talented individuals behind our success.</p>
                </div>

                <div className="container">
                    <div className="row gy-5">
                        <div className="col-lg-4 col-md-6 member" data-aos="fade-up" data-aos-delay="100">
                            <div className="member-img">
                                <img loading='lazy' src={Ceo} className="img-fluid" alt="Walter White" />
                                <div className="social">
                                    <a href="#"><i className="fa fa-instagram"></i></a>
                                    <a href="#"><i className="fa fa-linkedin"></i></a>
                                </div>
                            </div>
                            <div className="member-info text-center">
                                <h4>Walter White</h4>
                                <span>Chief Executive Officer</span>
                                <p>Aliquam iure quaerat voluptatem praesentium possimus unde laudantium vel dolorum distinctio dire flow</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 member" data-aos="fade-up" data-aos-delay="200">
                            <div className="member-img">
                                <img loading='lazy' src={Pm} className="img-fluid" alt="Sarah Jhonson" />
                                <div className="social">
                                    <a href="#"><i className="fa fa-instagram"></i></a>
                                    <a href="#"><i className="fa fa-linkedin"></i></a>
                                </div>
                            </div>
                            <div className="member-info text-center">
                                <h4>Sarah Jhonson</h4>
                                <span>Product Manager</span>
                                <p>Labore ipsam sit consequatur exercitationem rerum laboriosam laudantium aut quod dolores exercitationem ut</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 member" data-aos="fade-up" data-aos-delay="300">
                            <div className="member-img">
                                <img loading='lazy' src={cto} className="img-fluid" alt="William Anderson" />
                                <div className="social">
                                    <a href="#"><i className="fa fa-instagram"></i></a>
                                    <a href="#"><i className="fa fa-linkedin"></i></a>
                                </div>
                            </div>
                            <div className="member-info text-center">
                                <h4>William Anderson</h4>
                                <span>CTO</span>
                                <p>Illum minima ea autem doloremque ipsum quidem quas aspernatur modi ut praesentium vel tque sed facilis at qui</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 member" data-aos="fade-up" data-aos-delay="400">
                            <div className="member-img">
                                <img loading='lazy' src={account} className="img-fluid" alt="Amanda Jepson" />
                                <div className="social">
                                    <a href="#"><i className="fa fa-instagram"></i></a>
                                    <a href="#"><i className="fa fa-linkedin"></i></a>
                                </div>
                            </div>
                            <div className="member-info text-center">
                                <h4>Amanda Jepson</h4>
                                <span>Accountant</span>
                                <p>Magni voluptatem accusamus assumenda cum nisi aut qui dolorem voluptate sed et veniam quasi quam consectetur</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 member" data-aos="fade-up" data-aos-delay="500">
                            <div className="member-img">
                                <img loading='lazy' src={markating} className="img-fluid" alt="Brian Doe" />
                                <div className="social">
                                    <a href="#"><i className="fa fa-instagram"></i></a>
                                    <a href="#"><i className="fa fa-linkedin"></i></a>
                                </div>
                            </div>
                            <div className="member-info text-center">
                                <h4>Brian Doe</h4>
                                <span>Marketing</span>
                                <p>Qui consequuntur quos accusamus magnam quo est molestiae eius laboriosam sunt doloribus quia impedit laborum velit</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 member" data-aos="fade-up" data-aos-delay="600">
                            <div className="member-img">
                                <img loading='lazy' src={Op} className="img-fluid" alt="Josepha Palas" />
                                <div className="social">
                                    <a href="www.ailogixx.com" target="_blank"><i className="fa fa-instagram"></i></a>
                                    <a href="#"><i className="fa fa-linkedin"></i></a>
                                </div>
                            </div>
                            <div className="member-info text-center">
                                <h4>Josepha Palas</h4>
                                <span>Operation</span>
                                <p>Sint sint eveniet explicabo amet consequatur nesciunt error enim rerum earum et omnis fugit eligendi cupiditate vel</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Team;

import React, { useState } from "react";
import { Helmet } from "react-helmet"; // Import Helmet for SEO
import { Timestamp, collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db, auth } from "../../Source/firebaseConfig";
import { toast } from "react-toastify";

import "./Servicsfrom.css";
import Footer from "../../Footer/Footer";

export default function Servicsfrom() {
    const [formData, setFormData] = useState({
        Author: "",
        title: "",
        description: "",
        Phonenum: "",
        image: "",
        createdAt: Timestamp.now().toDate(),
    });

    const [progress, setProgress] = useState(0);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handlePublish = () => {
        if (!formData.title || !formData.Author || !formData.Phonenum || !formData.description) {
            toast("Please fill all the fields", { type: "error" });
            return;
        }

        const storageRef = ref(storage, `/projects/${Date.now()}${formData.image.name}`);
        const uploadImage = uploadBytesResumable(storageRef, formData.image);

        uploadImage.on(
            "state_changed",
            (snapshot) => {
                const progressPercent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(progressPercent);
            },
            (err) => {
                console.log(err);
            },
            () => {
                setFormData({
                    title: "",
                    description: "",
                    image: "",
                    Author: "",
                    Phonenum: "",
                });

                getDownloadURL(uploadImage.snapshot.ref).then((url) => {
                    const articleRef = collection(db, "projects");
                    addDoc(articleRef, {
                        title: formData.title,
                        description: formData.description,
                        imageUrl: url,
                        createdAt: Timestamp.now().toDate(),
                        Phonenum: formData.Phonenum,
                        Author: formData.Author,
                    })
                        .then(() => {
                            toast("Thanks! Our team will contact you via email as soon as possible. 🎉", { type: "success" });
                            setProgress(0);
                        })
                        .catch((err) => {
                            toast("Try Again", { type: "error" });
                        });
                });
            }
        );
    };

    return (
        <>
            <Helmet>
                <title>Start a Project </title>
                <meta name="description" content="Ready to bring your ideas to life? Start a project with Hamleen creative agency ! Fill out our form to get in touch and let our team help you turn your vision into reality." />
            </Helmet>

            <div className="container">
                <div className="p-3 mt-3">
                    <div className="dsds">
                        <h2>START A PROJECT</h2>
                        <p>Let's convert your idea to a real project</p>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Name</label>
                        <input
                            type="text"
                            name="Author"
                            placeholder="Full Name"
                            className="form-control"
                            value={formData.Author}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Email</label>
                        <input
                            type="text"
                            name="title"
                            placeholder="www.example@.com"
                            className="form-control"
                            value={formData.title}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Phone Number</label>
                        <input
                            type="phonenumber"
                            name="Phonenum"
                            placeholder="+92123456789"
                            className="form-control"
                            value={formData.Phonenum}
                            onChange={handleChange}
                        />
                    </div>
                    <label htmlFor="">Explain Your Needs</label>
                    <textarea
                        name="description"
                        className="form-control"
                        cols={5}
                        placeholder="Message"
                        rows={5}
                        value={formData.description}
                        onChange={handleChange}
                    />

                    {progress === 0 ? null : (
                        <div className="progress" style={{ height: "30px" }}>
                            <div className="progress-bar progress-bar-striped" style={{ width: `${progress}%`, paddingLeft: "65px" }}>
                                {`   Sending, please wait for notification`}
                            </div>
                        </div>
                    )}

                    <button
                        className="form-control mt-2 projectstart"
                        onClick={handlePublish}
                        style={{ background: "#1EE6AA", color: "black", fontWeight: "bold" }}
                    >
                        START
                    </button>
                </div>
            </div>
            <Footer />
        </>
    );
}

import React from 'react';
import { Helmet } from 'react-helmet';
import "./Cousess.css";
import { Link } from 'react-router-dom';
import Progressbar from "../../Gallery/Progressbar/Progressbar";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useState, useEffect } from "react";
import { db } from "../../Source/firebaseConfig";
import Footer from '../../Footer/Footer';
import Skilldevelopment from '../Skilldevelopment';

function Cousess() {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const articleRef = collection(db, "Skilldevelopment");
        const q = query(articleRef, orderBy("createdAt", "desc"));
        onSnapshot(q, (snapshot) => {
            const articles = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setArticles(articles);
        });
    }, []);

    // Assuming articles have a title to set as meta description
    const pageTitle = "Our Skill Development Programs";
    const pageDescription = articles.length > 0 ?
        `Explore our dynamic skill development programs: ${articles.map(article => article.title).join(', ')}` :
        "Explore our dynamic skill development programs";

    return (
        <div>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
            </Helmet>
            <section id="courses" className="courses section">
                <div className="section-title">
                    <h2>Our Programs</h2>
                    <p>Explore our dynamic skill development program's</p>
                </div>
                <div className="container" style={{ marginBottom: "52px" }}>
                    <div className="row" style={{ marginBottom: "34px" }}>
                        {articles.length === 0 ? (
                            <div className="">
                                <Progressbar />
                            </div>
                        ) : (
                            articles.map(({ Catagary, id, title, imageUrl, description, Pricepermouth }) => (
                                <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100" key={id}>
                                    <div className="course-item">
                                        <Link to={`/article/${id}`}><img src={imageUrl} className="img-fluid" alt="..." /></Link>
                                        <div className="course-content">
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <p className="category">{Catagary}</p>
                                                <p className="price">{Pricepermouth}</p>
                                            </div>
                                            <h3><Link to={`/article/${id}`}>{title}</Link></h3>
                                            <p className="description">{description.slice(0, 150)} .... </p>
                                            <div className="bottomsetion">
                                                <div className="diveenroll">
                                                    <Link to="/Skillform"><button className='' style={{ border: "none" }}>Enroll Now</button></Link>
                                                </div>
                                                <div className='divnext'>
                                                    <Link to={`/article/${id}`}><i className="fa fa-chevron-circle-right" aria-hidden="true"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </section>
            <Skilldevelopment />
        </div>
    );
}

export default Cousess;

import React, { useState } from "react";
import { Timestamp, collection, addDoc } from "firebase/firestore";
import { db } from "../../../Source/firebaseConfig";
import { toast } from "react-toastify";
import Footer from "../../../Footer/Footer";

export default function Applyingformintership() {

    const [formData, setFormData] = useState({
        Author: "",
        title: "",
        description: "",
        Phonenum: "",
        createdAt: Timestamp.now().toDate(),
        hear: "",
        interested: "",
        Gender: "",
        Intrestedcourse: "",

        startingdate: "",
        motivates: "",
        interestedpostion: "",
    });

    const [progress, setProgress] = useState(0);

    const handleChange = (e) => {
        const updatedFormData = { ...formData, [e.target.name]: e.target.value };
        setFormData(updatedFormData);

        const filledFields = Object.values(updatedFormData).filter(Boolean).length;
        const totalFields = Object.keys(updatedFormData).length;
        setProgress(Math.round((filledFields / totalFields) * 108));
    };

    const handlePublish = () => {
        if (
            !formData.title ||
            !formData.description ||
            !formData.Author ||
            !formData.Education ||
            !formData.interestedpostion ||
            !formData.hear ||
            !formData.interested ||
            !formData.Education ||

            !formData.Gender ||

            !formData.startingdate ||
            !formData.motivates ||
            !formData.Phonenum
        ) {
            toast("Please fill all the fields", { type: "error" });
            return;
        }

        const articleRef = collection(db, "appliedinterships");
        addDoc(articleRef, {
            ...formData,
            createdAt: Timestamp.now().toDate(),
        })
            .then(() => {
                toast("Thanks for Applying! We’ll review your application and contact you soon.🎉", { type: "success" });
                setFormData({
                    Author: "",
                    title: "",
                    description: "",
                    Phonenum: "",
                    createdAt: Timestamp.now().toDate(),
                    hear: "",
                    interested: "",
                    Gender: "",
                    Intrestedcourse: "",

                    startingdate: "",
                    motivates: "",
                    interestedpostion: "",
                });
                setProgress(0);
            })
            .catch((err) => {
                toast("Try Again", { type: "error" });
            });
    };

    return (
        <>
            <div style={{ fontFamily: "  Poppins" }}>
                <div className="div-title">
                    <h2 style={{ color: "black", marginTop: "40px" }}>Exciting internships await. Apply now!</h2>
                </div>
                <div className="container col-lg-6">
                    <div className="p-1">
                        <div className="d-flex">
                            <div className="row">
                                <div className="form-group col-lg-6">
                                    <label htmlFor="Author">Name</label>
                                    <input
                                        type="text"
                                        name="Author"
                                        placeholder="Full Name"
                                        className="form-control"
                                        value={formData.Author}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group col-lg-6">
                                    <label htmlFor="interestedpostion">Interested Position</label>
                                    <input
                                        type="text"
                                        name="interestedpostion"
                                        placeholder="Web-Developer, HR, Accountant etc."
                                        className="form-control"
                                        value={formData.interestedpostion}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group col-lg-6">
                                    <label htmlFor="Gender">Gender</label>
                                    <input
                                        type="text"
                                        name="Gender"
                                        placeholder="Male, Female"
                                        className="form-control"
                                        value={formData.Gender}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group col-lg-6">
                                    <label htmlFor="Phonenum">Phone Number</label>
                                    <input
                                        type="text"
                                        name="Phonenum"
                                        placeholder="+92123456789"
                                        className="form-control"
                                        value={formData.Phonenum}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="title">Email</label>
                            <input
                                type="email"
                                name="title"
                                placeholder="example@example.com"
                                className="form-control"
                                value={formData.title}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="Education">Highest Level of Education Completed</label>
                            <input
                                type="text"
                                name="Education"
                                className="form-control"
                                value={formData.Education}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="interested">Write About Your Work Experience</label>
                            <textarea
                                name="interested"
                                rows={2}
                                className="form-control"
                                value={formData.interested}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="form-group col-lg-12">
                            <label htmlFor="startingdate">Will you join full-time after completion? (If yes, please explain why.) </label>
                            <input
                                type="text"
                                name="startingdate"

                                className="form-control"
                                value={formData.startingdate}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group col-lg-12">
                            <label htmlFor="motivates">What Motivates You to Do Your Best Work?</label>
                            <input
                                type="text"
                                name="motivates"

                                className="form-control"
                                value={formData.motivates}
                                onChange={handleChange}
                            />

                        </div>

                        <div className="form-group">
                            <label htmlFor="hear">How Did You Hear About This Job?</label>
                            <input
                                type="text"
                                name="hear"
                                placeholder="Social Media"
                                className="form-control"
                                value={formData.hear}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="description">Message</label>
                            <textarea
                                name="description"
                                className="form-control"
                                cols={3}
                                placeholder="Message"
                                rows={2}
                                value={formData.description}
                                onChange={handleChange}
                            />
                        </div>
                        {progress === 0 ? null : (
                            <div className="progress" style={{ height: "30px", marginTop: "10px" }}>
                                <div
                                    className="progress-bar progress-bar-striped"
                                    style={{ width: `${progress}%`, paddingLeft: "65px" }}
                                >
                                    {`Progress: ${progress}%`}
                                </div>
                            </div>
                        )}
                        <button
                            className="form-control mt-2"
                            onClick={handlePublish}
                            style={{ background: "#1EE6AA", color: "black", fontWeight: "500", textTransform: "uppercase", marginBottom: "50px" }}
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

import React from 'react'
import { Link } from 'react-router-dom'
import "./Aboutusdetail.css"
import imagefor from "../Home/Hero/hero-bg.jpg"
import Footer from '../Footer/Footer'; import { Helmet } from 'react-helmet';
function Aboutusdetial() {

    return (
        <>

            <Helmet>
                <title>Choose Us</title>
                <meta name="description" content="Choose Hamleen  Your customers and brands will love you even more when you work with us. " />
            </Helmet>

            <div id="about" className="about" >
                <div className="container" data-aos="fade-up" >

                    <div className="row align-items-xl-center gy-5">

                        <div className="col-xl-6 content">
                            <h3>Choose Us</h3>
                            <h2 className='choseehamleenh2'>Choose<span style={{ color: "#1EE6AA", }}> Hamleen</span>   as your Creative Agency</h2>
                            <p className='chousep' >When you choose Hamleen as your creative agency, you're choosing a partner that not only brings your ideas to life but also adds a layer of creativity and excellence that sets you apart in your industry. Our mission is to help you not only succeed but also shine in a world where creativity is the currency of attention. </p>
                            <span style={{ marginTop: "", color: "#1EE6AA", fontSize: "20px", }}>Be Different</span>

                            <p style={{ marginTop: "5px", fontSize: "14px", lineHeight: "30px" }
                            } >Your customers and brands will love you even more when you work with us.</p>

                            <Link className='bookdemeo' to="/Servicsfrom" style={{ textDecoration: "none" }}>Let's Talk</Link>
                        </div>

                        <div className="col-xl-6" >
                            <div className="row  iconboxes">

                                <div className="col-md-6" data-aos="fade-up" data-aos-delay="200">
                                    <div className="icon-box">
                                        <i className="fa fa-road" aria-hidden="true"></i>
                                        <h3>Our Mission </h3>
                                        <p>Fueling brand success through creativity, strategy, media,tech and AI.</p>
                                    </div>
                                </div>

                                <div className="col-md-6" data-aos="fade-up" data-aos-delay="300">
                                    <div className="icon-box">
                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                        <h3>Our vision</h3>
                                        <p>
                                            Pioneering Innovative Design, Redefining <br />Global Creativity. </p>
                                    </div>
                                </div>

                                <div className="col-md-12" data-aos="fade-up" data-aos-delay="400">
                                    <div className="icon-box">
                                        <i className="fa fa-handshake-o" aria-hidden="true"></i>
                                        <h3>Our Values</h3>
                                        <p>Impactful, Premium, and Speed.</p>
                                    </div>
                                </div>





                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <section id="features" className="features container ">



                <div className="row gy-4 align-items-stretch justify-content-between features-item ">

                    <div className="col-lg-6 d-flex justify-content-center flex-column" data-aos="fade-up">

                        <h3 className='wherwefit'>Where do we fit into this  <span style={{ color: "#1EE6AA" }}> world</span>? </h3>

                        <p >Hamleen is here to help agencies and brands, media owners and publishers, as well as e-commerce platforms, grow their businesses. We help you better understand your readers, users, gamers, and listeners to create better digital experiences.</p>
                    </div>
                    <div className="col-lg-6 d-flex align-items-center features-img-bg" data-aos="zoom-out">
                        <img src={imagefor} alt="" class="stack-back" />
                    </div>
                </div>


            </section>

            <Footer />
        </>
    )
}

export default Aboutusdetial

import "./Singlecourse.css"
import { Link } from 'react-router-dom'
import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../Source/firebaseConfig";
function Singlecourse() {
    const { id } = useParams();
    const [article, setArticle] = useState(null);

    useEffect(() => {
        const docRef = doc(db, "Skilldevelopment", id);
        onSnapshot(docRef, (snapshot) => {
            setArticle({ ...snapshot.data(), id: snapshot.id });
        });
    }, []);
    return (
        <div><section id="courses-course-details" class="courses-course-details section ">

            <div className="container" data-aos="fade-up">
                <div className="row">   {article && (<>
                    <div class="col-lg-8">
                        <img src={article.imageUrl} class="img-fluid" alt="" />
                        <h3 style={{ color: " #000000b4" }}>{article.title} </h3>
                        <p style={{ fontFamily: "Poppins", fontSize: "14px", lineHeight: "30px" }}>
                            {article.description}
                        </p>
                    </div>
                    <div className="col-lg-4" style={{ fontFamily: "Poppins", fontSize: "13px" }}>
                        <div className="course-info d-flex justify-content-between align-items-center">

                            <p style={{ fontWeight: "600" }}> We have a mandatory course for each and every one on How to sell</p>
                        </div>
                        <div className="course-info d-flex justify-content-between align-items-center">
                            <h5 style={{ fontFamily: " Poppins" }}>Trainer</h5>
                            <p href="" style={{ color: "#1EE6AA" }}>{article.Trainer}</p>
                        </div>

                        <div className="course-info d-flex justify-content-between align-items-center">

                            <p>Time period</p>      <p> {article.Programtimeperiod}</p>
                        </div>

                        <div className="course-info d-flex justify-content-between align-items-center">
                            <h5 style={{ fontFamily: " Poppins" }}>Available Seats</h5>
                            <h5 style={{ color: "red", fontWeight: "bold" }}>{article.Numberofseatsavailable}</h5>
                        </div>

                        <div className="course-info d-flex justify-content-between align-items-center">
                            <h5 style={{ fontFamily: " Poppins" }}>Starting from</h5>
                            <p> {article.Startingfrom}</p>
                        </div><div className="course-info d-flex justify-content-between align-items-center">
                            <h5 style={{ fontFamily: " Poppins" }}>Price</h5>
                            <p> <span style={{ color: "#1ee6aa" }}>{article.Pricepermouth}PKR </span>Monthly </p>
                        </div>

                        <div className="course-info d-flex justify-content-between align-items-center">
                            <h5 style={{ fontFamily: " Poppins" }}>Last Date REG</h5>
                            <p> {article.Lastdateofenrollment}</p>
                        </div>
                        <div class="diveenrollsingle">
                            <Link to="/Skillform"><button className='' style={{ border: "none" }}>Enroll Now</button></Link>
                        </div>


                    </div>

                </>
                )}

                </div>

            </div>

        </section ></div >
    )
}

export default Singlecourse
// Mainlogscreen.js
import React, { useEffect, useState } from 'react';
import './Mainlogscreen.css'; // Ensure this path is correct
import Image from "./Hamleen.png";

const Mainlogscreen = ({ onLoaded }) => {
    const [showSplash, setShowSplash] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSplash(false);
            onLoaded(); // Notify that splash screen is done
        }, 2000); // Duration of the splash screen

        return () => clearTimeout(timer);
    }, [onLoaded]);

    if (!showSplash) return null;

    return (
        <div className="splash-screen">
            <img src={Image} alt="Hamleen Logo" />
        </div>
    );
};

export default Mainlogscreen;

import React from 'react'
import "./OurProducts.css"
import Ailogixx from "./Happy (3).png"
import Comingweekend from "./Happy (5).png"
import pet from "./Happy (7).png"
import Footer from '../Footer/Footer'

function OurProducts() {
    return (
        <div>
            <section id="recent-posts" className="recent-posts">

                <div className="container section-title" data-aos="fade-up">
                    <h2>Our Products </h2>
                    <p >Discover a world of innovative products that cater to your needs
                    </p>
                </div>

                <div className="container">

                    <div className="row">

                        <div className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                            <article>

                                <div className="  ">
                                    <img loading='lazy' src={Ailogixx} alt="" class="img-fluid" />
                                </div>

                                <h5 className="post-category">AI tools and info</h5>

                                <h2 className="title">
                                    <a href="https://www.ailogixx.com/" target="blank" style={{ fontSize: "14px" }}>Ailogixx is your platform for everything AI. Join us in the world of artificial intelligence,
                                        where both experts and enthusiasts share their insights, discuss the latest AI developments, and
                                        unveil new AI tools.</a><br />
                                    <a href="https://www.ailogixx.com/" target="blank" style={{ color: "#1EE6AA", }}>www.AilogixX.com</a>
                                </h2>


                            </article>
                        </div>

                        <div className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                            <article>

                                <div className="post-img">
                                    <img loading='lazy' src={Comingweekend} alt="" className="img-fluid" />
                                </div>

                                <h5 className="post-category">Event Updater</h5>

                                <h2 className="title">
                                    <a href="https://comingweekend.com/" target="blank" style={{ fontSize: "14px" }}>Coming Weekend - Your go-to destination for discovering the exciting events happening in your area. Get a sneak peek at what's on the horizon and make the most of your upcoming weekends. </a><br />
                                    <a href="https://comingweekend.com/" target="blank" style={{ color: "#1EE6AA", }}>www.ComingWeekend.com</a>
                                </h2>


                            </article>
                        </div>

                        <div className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                            <article>

                                <div className="post-img">
                                    <img loading='lazy' src={pet} alt="" className="img-fluid" />
                                </div>

                                <h5 className="post-category">ComingSoon</h5>

                                <h2 className="title">
                                    <a href="hamleen" target="blank" style={{ fontSize: "14px" }}>Get ready to explore a whole new way to connect with the world
                                        of content creation
                                    </a><br />

                                </h2>



                            </article>
                        </div>

                    </div>

                </div>

            </section >
            <Footer />
        </div >
    )
}

export default OurProducts
import React from 'react'
import "./howwework.css"
import Chess from "./chess.png"
import pen from "./PEN.png"
import Matkating from "./marketing.png"
function Howwework() {
    return (




        <div className="container howweworkcontianer" data-aos="fade-up" data-aos-delay="100">

            <div className="row  " >

                <div className='processtitle'> <h2> Map we follow</h2>
                    <p>Here is how we will help you position and grow your brand effectively.</p></div>

                <div className="row  ">
                    <div className="col-md-4 workbox">
                        <div className="info-item" data-aos="fade" data-aos-delay="200">
                            <div className='howimgeconiner'>

                                <img src={Chess} alt="..." class="img-thumbnail"></img>
                                <h4><span className='numofdev'>1.</span> Strategy</h4>
                                <p>We help you define your brand, understand your users, clarify your message, and prioritise your marketing.</p>

                            </div>


                        </div>
                    </div>


                    <div className="col-md-4 workbox">
                        <div className="info-item" data-aos="fade" data-aos-delay="200">
                            <div className='howimgeconiner'>
                                <img src={pen} alt="..." class="img-thumbnail"></img>
                                <h4><span className='numofdev'>2.</span> Design</h4>
                                <p>We create unique brand experiences through identity, video, messaging, and web design.</p>

                            </div>

                        </div>
                    </div>
                    <div className="col-md-4 workbox">
                        <div className="info-item" data-aos="fade" data-aos-delay="200">
                            <div className='howimgeconiner'>
                                <img src={Matkating} alt="..." class="img-thumbnail"></img>
                                <h4><span className='numofdev'>3.</span> Marketing</h4>
                                <p>We connect you with your target audience using tailored digital and traditional marketing.</p>

                            </div>



                        </div>
                    </div>



                </div>

            </div>





        </div>




    )
}

export default Howwework
import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../Source/firebaseConfig';
import Progressbar from './Progressbar/Progressbar';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';  // Import Helmet
import './Carrers.css';
import '../Home/HomeServicessection/Homeservices.css';

function Carrers() {
    const [articles, setArticles] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const articlesPerPage = 6;

    useEffect(() => {
        const articleRef = collection(db, 'Fulltimejob');
        const q = query(articleRef, orderBy('createdAt', 'desc'));
        onSnapshot(q, (snapshot) => {
            const articles = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setArticles(articles);
        });
    }, []);

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    };

    const filteredArticles = articles.filter((article) =>
        article.title.toLowerCase().includes(searchInput.toLowerCase()) ||
        article.description.toLowerCase().includes(searchInput.toLowerCase())
    );

    const indexOfLastArticle = currentPage * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            {/* Helmet for SEO */}
            <Helmet>
                <title>Full-time Jobs at Hamleen </title>
                <meta
                    name="description"
                    content={`Explore full-time job opportunities. ${filteredArticles.length} positions available. Find your next career!`}
                />
            </Helmet>

            <div id="services" className="services">
                <div className="container" data-aos="fade-up">
                    <div className="div-title">
                        <h2>Full-time jobs</h2>
                    </div>

                    <div className="row">
                        <div className='portfoioldevv'>
                            <Link to="/Carrers" className='Carreslink'>Full-time</Link>
                            <Link to="/IntershipProgroms" className='intershiplink'>Intership</Link>
                            <Link to="/Contentcreator" className='creator'> Content Creator</Link>
                        </div>

                        {/* Search Bar */}
                        <div className="search-bar">
                            <input
                                type="text"
                                placeholder="Search jobs you looking for ......"
                                value={searchInput}
                                onChange={handleSearchChange}
                                style={{ marginTop: "20px" }}
                            />
                        </div>

                        {articles.length === 0 ? (
                            <div className="Loaderitemjobs">
                                <Progressbar />
                            </div>
                        ) : filteredArticles.length === 0 ? (
                            <p>No Jobs available</p>
                        ) : (
                            currentArticles.map(({ id, title, imageUrl, description, userId, numberofseats }) => (
                                <div
                                    key={id}
                                    className="col-md-6 d-flex align-items-stretch"
                                    data-aos="fade-up"
                                    data-aos-delay="100"
                                >
                                    <div className="icon-box">
                                        <h4>{title}</h4>
                                        <p className="description">{description}</p>
                                        <div className="bottomsectioncontainer">
                                            <Link to="/Applyingform">
                                                <button>Apply</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>

                    {/* Pagination */}
                    <ul className="pagination">
                        {Array.from({ length: Math.ceil(filteredArticles.length / articlesPerPage) }, (_, i) => (
                            <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                <a href="#!" className="page-link" onClick={() => paginate(i + 1)}>
                                    {i + 1}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default Carrers;

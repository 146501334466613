import React from 'react';
import { Link } from 'react-router-dom';
import Footer from "../Footer/Footer";
import "./Servicesdeatail.css";
import { Helmet } from 'react-helmet';
function Servicesdeatial() {
    return (
        <div> <Helmet>
            <title>Capabilities</title>
            <meta name="description" content="Hamleen is here to help agencies and brands, media owners and publishers, as well as e-commerce platforms, grow their businesses" />
        </Helmet>

            <div className="section-title">
                <h2 style={{ marginTop: "50px" }}>Capabilities</h2>
                <p>Tailored solutions for your business's success</p>
            </div>

            <div className="custom-component container py-5">
                <div className="row align-items-center">
                    <div className="col-lg-6 custom-text-section">
                        <p className="custom-welcome" >Strategy</p>
                        <h1 className="custom-title">
                            Without <span style={{ color: "#1EE6AA" }}>Strategy</span> No Impact
                        </h1>
                        <ul className="custom-list">
                            <li>Brand Strategy</li>
                            <li>Marketing Strategy</li>
                            <li>Design Strategy</li>
                            <li>Strategy Workshops</li>
                        </ul>
                        <Link to="/Servicsfrom" style={{ textDecoration: "none" }}>
                            <a href="#" className="btnservices">Let's Talk</a>
                        </Link>
                    </div>
                    <div className="col-lg-6" style={{ marginTop: "20px" }}>
                        <img src="https://images.unsplash.com/photo-1523875194681-bedd468c58bf?q=80&w=1742&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Example" className="img-fluid custom-image" />
                    </div>
                </div>
            </div>

            <div className="bg-dark" style={{ marginTop: "100px", paddingTop: "50px", paddingBottom: "50px" }}>
                <div className="custom-component container py-5 text-light">
                    <div className="row align-items-center">
                        <p className="custom-welcome" >Branding</p>
                        <div className="col-lg-6" style={{ marginBottom: "20px" }}>
                            <img src="https://images.unsplash.com/photo-1416339134316-0e91dc9ded92?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Example" className="img-fluid custom-image" />
                        </div>
                        <div className="col-lg-6 custom-text-section">
                            <h1 className="custom-title text-light">
                                No Brands Without <span style={{ color: "#1EE6AA" }}>Branding</span>
                            </h1>
                            <ul className="custom-list text-light">
                                <li>Brand Identity</li>
                                <li>Verbal Identity</li>
                                <li>Brand Guidelines</li>
                                <li>Brand Experiences</li>
                                <li>Brand Messaging</li>
                                <li>Brand Architecture</li>
                                <li>Environmental Design</li>
                            </ul>
                            <a href="#" className="btnservicessbrands">Let's Talk</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="custom-component container" style={{ marginTop: "100px" }}>
                <div className="row align-items-center">
                    <div className="col-lg-6 custom-text-section">
                        <p className="custom-welcome" >Marketing</p>
                        <h1 className="custom-title">
                            No Leads Without <span style={{ color: "#1EE6AA" }}>Marketing</span>
                        </h1>
                        <ul className="custom-list">
                            <li>SEO</li>
                            <li>Influencers</li>
                            <li>Email Marketing</li>
                            <li>Digital Marketing</li>
                            <li>Content Marketing</li>
                            <li>Social Media Marketing</li>
                            <li>Search Engine Marketing</li>
                        </ul>
                        <Link to="/Servicsfrom" style={{ textDecoration: "none" }}>
                            <a href="#" className="btnservices">Let's Talk</a>
                        </Link>
                    </div>
                    <div className="col-lg-6" style={{ marginTop: "20px" }}>
                        <img src="https://images.unsplash.com/photo-1552664730-d307ca884978?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Example" className="img-fluid custom-image" />
                    </div>
                </div>
            </div>

            <div style={{ marginTop: "100px", paddingTop: "50px", paddingBottom: "50px", marginBottom: "50px" }}>
                <div className="custom-component container py-5 text-light">
                    <div className="row align-items-center">
                        <p className="custom-welcome" style={{ color: "#1EE6AA", marginTop: "10px" }}>Web Design</p>
                        <div className="col-lg-6" style={{ marginBottom: "20px" }}>
                            <img src="https://images.unsplash.com/photo-1504384308090-c894fdcc538d?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Example" className="img-fluid custom-image" />
                        </div>
                        <div className="col-lg-6 custom-text-section">
                            <h1 className="custom-title">
                                No <span style={{ color: "#1EE6AA" }}>Customer</span> Connection Without a Site
                            </h1>
                            <ul className="custom-list">
                                <li>E-commerce</li>
                                <li>Website Design</li>
                                <li>Custom Web Apps</li>
                                <li>User Experience Design</li>
                                <li>Wireframing and Prototyping</li>
                                <li>Content Management Systems (CMS)</li>
                            </ul>
                            <Link to="/Servicsfrom" style={{ textDecoration: "none" }}>
                                <a href="#" className="btnservicess">Let's Talk</a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Servicesdeatial;
